import { ActionTypes } from '@src/redux/actions';
import { DefaultStateTypes } from './loaderReducer.types';
import { LoaderDispatchTypes } from '@src/redux/actions/loader/loaderActions.types';

const defaultState: DefaultStateTypes = {
  loadedAssets: new Set(),
  expectedAssets: new Set(),
};

const userLocationReducer = (state = defaultState, action: LoaderDispatchTypes) => {
  switch (action.type) {
    case ActionTypes.LOAD_SVG_START:
      return {
        ...state,
        expectedAssets: new Set(state.expectedAssets).add(action.payload),
      };
    case ActionTypes.LOAD_SVG_COMPLETE:
      const newLoadedAssets = new Set(state.loadedAssets);
      newLoadedAssets.add(action.payload);
      return {
        ...state,
        loadedAssets: newLoadedAssets,
      };
    default:
      return state;
  }
};

export default userLocationReducer;
