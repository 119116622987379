import delay from './delay';
import { FetchGraphQLDataParamTypes } from './utils.types';

export default async function fetchGraphQLData<T>({
  url = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}?access_token=${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
  method = 'POST',
  query,
  variables,
  cache,
}: FetchGraphQLDataParamTypes): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
  };

  // Retry parameters
  const maxRetries = 5;
  const retryDelay = 5000; // 3 seconds

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      // Include the variables in the body if they exist
      const body = JSON.stringify(variables ? { query, variables } : { query, variables });

      const response = await fetch(url, {
        cache: cache ? cache : process.env.NEXT_PUBLIC_ENVIRONMENT === 'live' ? 'force-cache' : 'no-store',
        method,
        headers,
        body,
      });

      if (!response?.ok) {
        let errorDetails;
        try {
          const errorJson = await response.json();
          errorDetails = JSON.stringify(errorJson, null, 4);
        } catch (jsonError) {
          errorDetails = await response.text();
        }

        if (response.status === 429 && attempt < maxRetries) {
          // Rate limit hit; wait before retrying
          // eslint-disable-next-line no-console
          console.warn(
            `Rate limit exceeded. Retrying in ${retryDelay / 1000} seconds... (Attempt ${attempt}/${maxRetries})`,
          );
          await delay({ ms: retryDelay });
          continue; // Retry the request
        }

        throw new Error(
          `Network response was not ok: ${response?.status} ${response?.statusText}\nDetails: ${errorDetails}`,
        );
      }

      const data = await response.json();

      if (!data || !data?.data) {
        throw new Error('Response JSON does not contain data.');
      }

      return data?.data as T;
    } catch (error) {
      if (attempt === maxRetries) {
        // After all retries fail
        if (error instanceof Error) {
          throw new Error(`Fetching data failed after ${maxRetries} attempts: ${error?.message}`);
        } else {
          throw new Error('An unknown error occurred while fetching data.');
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Error occurred: ${error instanceof Error ? error.message : 'Unknown error'}. Retrying...`);
        await delay({ ms: retryDelay }); // Wait before retrying
      }
    }
  }

  // This should never be reached
  throw new Error('Unexpected error in fetchGraphQLData.');
}
