import { combineReducers } from 'redux';

import windowDimensionReducer from './windowDimensionReducer/windowDimensionReducer';
import loaderReducer from './loaderReducer/loaderReducer';
import newsReducer from './newsReducer/newsReducer';

const rootReducer = combineReducers({
  windowRXS: windowDimensionReducer,
  loaderRXS: loaderReducer,
  newsRXS: newsReducer,
});

export default rootReducer;
