export const SPACE = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
export const ACCESS_TOKEN = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';
export const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE}?access_token=${ACCESS_TOKEN}`;
export const IS_PREVIEW = process.env.NEXT_PUBLIC_ENVIRONMENT !== 'live';

export const MAX_BLOG_ENTRIES_PER_PAGE = 24 as const;

// ALgolia API key
export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string;
export const ALGOLIA_API_ADMIN_KEY = process.env.NEXT_PUBLIC_ALGOLIA_API_ADMIN_KEY as string;
